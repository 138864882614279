import { css } from 'styled-components';

import {
  FontSize,
  FontFamily,
  Components,
  TextTransformOptions,
} from '@powdr/constants';

import RefrigeratorDeluxeExtraBoldWoff from '../assets/fonts/Refrigerator-Deluxe-Extra-Bold.woff';

// For some reason, this font just won't load properly via the CSS version,
// have tried TONS of different fixes
// so this will be here for now until the font is replaced or we figure out a fix
const FontFaces = css`
  @font-face {
    font-family: 'Refrigerator Deluxe - Extra Bold';
    src:url(${RefrigeratorDeluxeExtraBoldWoff}) format('woff');
    font-display: swap;
  }
`;

const fonts = {
  [FontFamily.BASE_FONT]: {
    fontFamily: '"Gotham Book"',
    textTransform: TextTransformOptions.NO_TRANSFORM,
    defaultSize: FontSize.REG50,
    scaling: 1,
  },
  [FontFamily.PRIMARY_FONT]: {
    fontFamily: '"Refrigerator Deluxe - Extra Bold"',
    textTransform: TextTransformOptions.UPPERCASE,
    defaultSize: FontSize.MED30,
    scaling: 1,
  },
  [FontFamily.SECONDARY_FONT]: {
    fontFamily: '"Gotham Bold"',
    textTransform: TextTransformOptions.UPPERCASE,
    defaultSize: FontSize.REG50,
    scaling: 1,
  },
};

const fontOverrides = {
  [Components.CONTENT_BLOCK_HERO]: {
    header: {
      size: FontSize.LRG40,
    },
    copy: {
      size: FontSize.REG40,
    },
    headerMobile: {
      size: '55px',
    },
  },
};

export const fontTheme = {
  fonts,
  fontOverrides,
  FontSize,
  FontFaces,
};
